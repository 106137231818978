import React from "react";
import { FaCheck } from "react-icons/fa6";
import CustomButton from "../../Controls/CustomButton";

function ComparisonChart() {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <h1 className="text-left text-4xl leading-tight font-light absolute top-4">
              Compare our plans
            </h1>
          </th>
          <th>
            <div className="flex flex-col gap-y-3">
              <h6 className="text-2xl font-normal">Basic</h6>
              <CustomButton
                text="Get Started"
                className="!py-1 !px-4 !gap-x-3 !w-fit mx-auto"
                textClass="!text-[14px]"
                iconClass="w-[14px]"
              />
            </div>
          </th>
          <th>
            <div className="flex flex-col gap-y-3">
              <h6 className="text-2xl font-normal">Standard</h6>
              <CustomButton
                text="Get Started"
                className="!py-1 !px-4 !gap-x-3 !w-fit mx-auto"
                textClass="!text-[14px]"
                iconClass="w-[14px]"
              />
            </div>
          </th>
          <th>
            <div className="flex flex-col gap-y-3">
              <h6 className="text-2xl font-normal">Enterprise</h6>
              <CustomButton
                text="Get Started"
                className="!py-1 !px-4 !gap-x-3 !w-fit mx-auto"
                textClass="!text-[14px]"
                iconClass="w-[14px]"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="left-col" colSpan="4">
            <h5>Initial</h5>
          </td>
        </tr>
        <tr>
          <td>Bases</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Records per base</td>
          <td>2000</td>
          <td>20,000</td>
          <td>200,000</td>
        </tr>
        <tr>
          <td>Workspaces</td>
          <td>1</td>
          <td>1</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>Max number of commenters per workspace</td>
          <td>50</td>
          <td>Unlimited</td>
          <td>Unlimited</td>
        </tr>
        <tr>
          <td>iOS and Android mobile apps</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Tool Designer</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Interface</h5>
          </td>
        </tr>
        <tr>
          <td>List</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Grid</td>
          <td>
            <FaCheck />
          </td>{" "}
          <td>
            <FaCheck />
          </td>{" "}
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Kanban</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Calendar</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Dashboard</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Inbox</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Forms</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Management</h5>
          </td>
        </tr>

        <tr>
          <td>Timeline</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Usage</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Conditional Filtering</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Dynamic Filtering</td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Extensions</h5>
          </td>
        </tr>
        <tr>
          <td>Pre-built extensions</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Scripting</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Automations</h5>
          </td>
        </tr>
        <tr>
          <td>Runs per month</td>
          <td>100</td>
          <td>20,000</td>
          <td>100,000</td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Advanced Permissions</h5>
          </td>
        </tr>
        <tr>
          <td>Password and domain restrictions</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Personal and locked views</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>User groups</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Granular interface permissions</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>

        <tr>
          <td className="left-col" colSpan="4">
            <h5>Support</h5>
          </td>
        </tr>
        <tr>
          <td>Help Center</td>
          <td>
            {" "}
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Product Support</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Email Support</td>
          <td></td>
          <td></td>
          <td>
            <FaCheck />
          </td>
        </tr>
        <tr>
          <td>Professional Services</td>
          <td></td>
          <td>
            <FaCheck />
          </td>
          <td>
            <FaCheck />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ComparisonChart;
