const PricingData = [
  {
    title: "Basic",
    subTitle: "Essential features for small projects and teams.",
    price: "0",
    buttonText: "Start Free",
    features: [
      "Automated browser testing",
      "Scripted user interactions",
      "Scheduled test runs",
      "Basic monitoring (uptime checks)",
      "Limited to 100 test runs per month",
      "Email support",
      "Community forum access",
    ],
  },
  {
    title: "Standard",
    subTitle: "Advanced features for growing teams and projects.",
    price: "49",
    buttonText: "Sign Up",
    features: [
      "Unlimited test runs",
      "Script debugging and error reporting",
      "Real-time performance monitoring",
      "Customizable alerts and notifications",
      "API access",
      "Priority email support",
      "Community forum access",
      "Integration with CI/CD tools",
    ],
  },
  {
    title: "Enterprise",
    subTitle: "Tailored solutions for large-scale projects and organizations.",
    price: "99",
    buttonText: "Contact Sales",
    features: [
      "Customizable test environments",
      "Dedicated account manager",
      "24/7 priority support",
      "Advanced analytics and reporting",
      "Customizable alerts and notifications",
      "API access",
      "On-premise deployment option",
      "Enterprise-grade security features",
      "Gantt and timeline view",
      "Expanded color, formatting, and calendar options",
    ],
  },
];

export default PricingData;
