import React from "react";
import PageTag from "../Controls/PageTag";
import Reveal from "../Controls/Reveal";
import PricingData from "../../data/pricingData";
import CustomButton from "../Controls/CustomButton";
import { FaCheck } from "react-icons/fa6";
import AddOnData from "../../data/addOnData";
import KeyStats from "../Home/components/KeyStats";
import ComparisonChart from "./components/ComparisonChart";

function Pricing() {
  return (
    <section className="py-24">
      <div className="text-center">
        <PageTag text={"Pricing"} />
        <div className="mt-12 w-full lg:w-8/12 mx-auto">
          <Reveal>
            <h2 className="text-[#f1f1f1] text-4xl lg:text-6xl xl:pb-2">
              Adaptable automation for businesses of all sizes with a plan
              tailored to every organization's needs.
            </h2>
          </Reveal>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <Reveal className=" h-full p-2 xl:p-10 w-full">
          <div className="flex flex-col gap-y-10 lg:flex-row gap-x-6 items-start justify-center mt-16">
            {PricingData.map((data) => {
              return (
                <div className="pricing-card">
                  <div className="flex flex-col gap-y-0">
                    <h2 className="text-[28px] font-semibold">{data.title}</h2>
                    <p className="text-sm font-normal">{data.subTitle}</p>
                  </div>
                  <div className="flex gap-2 items-baseline relative w-fit mt-4">
                    <span className="absolute top-0 text-xl">$ </span>
                    <h1 className="text-7xl ml-4 font-semiBold">
                      {data.price}{" "}
                    </h1>
                    <p className="font-light">per user/month</p>
                  </div>
                  <CustomButton
                    text={data.buttonText}
                    className={"mt-8 justify-center w-full mx-auto "}
                    textClass={"text-[20px]"}
                  />
                  <div className="mt-12 flex flex-col gap-y-2">
                    <p className="text-lg text-[#eee]">
                      {data.title} includes:{" "}
                    </p>
                    {data.features.map((feature) => {
                      return (
                        <div className=" flex gap-x-3 items-center text-sm">
                          <FaCheck />
                          <p>{feature}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Reveal>
        <div className="mt-10  mx-2 xl:mx-10 text-left flex flex-col gap-y-10 xl:flex-row items-start justify-between gap-6">
          <div className="w-full xl:w-6/12">
            <Reveal>
              <h1 className="text-white text-4xl md:text-5xl leading-tight w-full mx-auto">
                Create complete solutions using Autonomu and its integrated
                add-ons.
              </h1>
            </Reveal>
            <Reveal>
              <ul className="text-white mt-6 flex flex-col gap-y-2">
                <li className="flex gap-x-4 items-center">
                  <FaCheck />
                  <p className="text-greyTone">
                    Summarize and derive insights from meeting notes, articles,
                    etc.
                  </p>
                </li>
                <li className="flex gap-x-4 items-center">
                  <FaCheck />
                  <p className="text-greyTone">
                    Organize feedback and assets into categories efficiently.
                  </p>
                </li>
                <li className="flex gap-x-4 items-center">
                  <FaCheck />
                  <p className="text-greyTone">
                    Direct tasks to appropriate individuals seamlessly.
                  </p>
                </li>
                <li className="flex gap-x-4 items-center">
                  <FaCheck />
                  <p className="text-greyTone">
                    Produce preliminary content drafts effortlessly.
                  </p>
                </li>
                <li className="flex gap-x-4 items-center">
                  <FaCheck />
                  <p className="text-greyTone">
                    Facilitate text translation seamlessly.
                  </p>
                </li>
              </ul>
            </Reveal>
          </div>
          <div className="w-full xl:w-6/12 flex flex-col gap-y-5">
            {AddOnData.map((data) => {
              return (
                <div className=" bg-lightMainColor border-2 border-borderColor rounded-lg p-6">
                  <div className="flex items-center gap-4 text-white">
                    {data.icon}
                    <p className="text-xl leading-tight xl:text-2xl font-bold">
                      {data.title}
                    </p>
                  </div>
                  <div className="mt-3">
                    <p className="text-greyTone font-sm font-extraLight">
                      {data.subTitle}
                    </p>
                  </div>
                  <div className="flex justify-between items-end mt-4">
                    <div>
                      <p className="font-bold text-white">Starts at</p>
                      <div className="flex items-baseline relative text-white">
                        <span className="absolute top-0 text-sm">$</span>
                        <p className="ml-3 text-xl">{data.price}</p>
                        <span className="text-sm">/mo</span>
                      </div>
                    </div>
                    <div>
                      <CustomButton
                        text="Get Started"
                        className="!py-1 !px-4 !gap-x-3"
                        textClass="!text-[14px]"
                        iconClass="w-[14px]"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <KeyStats />
      </div>

      <div className="hidden xl:block mt-10 comparison-chart">
        <ComparisonChart />
      </div>
    </section>
  );
}

export default Pricing;
