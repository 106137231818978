import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import Reveal from "../../Controls/Reveal";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import WorkflowOrchestrationHeader from "./../../../media/workflow-orchestration.png";
import SeamlessCoord from "./../../../media/seamless-coord.png";
import AdvancedCapabilities from "./../../../media/advanced-capabilities.png";
import WorkflowMgmt from "./../../../media/workflow-mgmt.png";

function WorkflowOrchestration() {
  useDocumentTitle("Workflow Orchestration | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col items-center gap-y-5 lg:flex-row">
        <div className="w-full lg:w-6/12 mx-auto flex flex-col gap-y-6">
          <Reveal>
            <h3 className="text-white text-5xl">
              Streamline your workflows with our orchestration platform.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Optimize operations, reduce complexity, and increase efficiency
              with our comprehensive workflow orchestration solutions.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text={"Get Started"} />
          </Reveal>
        </div>
        <div className="w-full lg:w-6/12">
          <Reveal>
            <img
              src={WorkflowOrchestrationHeader}
              alt="workflow-orchestration"
              className="mx-auto"
            />
          </Reveal>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="w-full lg:w-6/12 mx-auto">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              Enhance your workflow management with our advanced orchestration
              platform.
            </h4>
          </Reveal>
        </div>
        <div className="flex flex-col gap-y-10 w-full lg:w-10/12 mx-auto">
          <div className="flex flex-col items-center items-ce gap-y-5 lg:flex-row">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img src={SeamlessCoord} alt="seamless-coord" className="" />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">
                  Seamless Coordination
                </h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate task scheduling and execution
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Ensure seamless integration between different processes
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Monitor workflow performance in real-time
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Generate detailed reports for insights
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img
                  src={AdvancedCapabilities}
                  alt="advanced-capabilities"
                  className="mx-auto"
                />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">
                  Advanced Capabilities
                </h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Utilize state-of-the-art workflow orchestration tools
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Support for various integration platforms
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Easily manage complex workflows and dependencies
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Integrate with existing tools and platforms seamlessly
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row items-center">
        <div className="w-full lg:w-6/12">
          <Reveal>
            <img src={WorkflowMgmt} alt="workflow-mgmt" className="mx-auto" />
          </Reveal>
        </div>
        <div className="w-full lg:w-6/12 text-left">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              Comprehensive Workflow Management
            </h4>
          </Reveal>

          <Reveal>
            <p className="text-greyTone mt-4 mb-10">
              Our orchestration platform offers a complete suite of tools for
              managing and optimizing your workflows. From simple tasks to
              complex processes, we provide the features and integrations needed
              for effective workflow orchestration. Enhance your productivity
              and streamline operations with our solutions.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text="Discover More" />
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default WorkflowOrchestration;
