import React from "react";
import useDocumentTitle from "../../Controls/DynamicDocumentTitle";
import Reveal from "../../Controls/Reveal";
import CustomButton from "../../Controls/CustomButton";
import BorderSeparator from "../../Controls/BorderSeparator";
import { FaCircleCheck } from "react-icons/fa6";
import AutomatedTesting from "./../../../media/automated-testing-hero.png";
import StreamlineProcess from "./../../../media/streamline-process.png";
import PowerfulTools from "./../../../media/powerfull-tools.png";
import RobustEco from "./../../../media/robust-eco.png";

function AutomationTesting() {
  useDocumentTitle("Automated Testing | Autonomu");
  return (
    <section className="py-24">
      <div className="flex flex-col items-center gap-y-5 lg:flex-row">
        <div className="w-full lg:w-6/12 mx-auto flex flex-col gap-y-6">
          <Reveal>
            <h3 className="text-white text-5xl">
              Accelerate your testing processes with automation.
            </h3>
          </Reveal>
          <Reveal>
            <p className="text-greyTone">
              Enhance testing efficiency, minimize manual errors, and deliver
              high-quality software faster with our automation testing
              solutions.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text={"Start Testing"} />
          </Reveal>
        </div>
        <div className="w-full lg:w-6/12">
          <Reveal>
            <img
              src={AutomatedTesting}
              alt="streamline-process"
              className="mx-auto"
            />
          </Reveal>
        </div>
      </div>
      <BorderSeparator />
      <div className="flex flex-col text-center gap-y-24">
        <div className="w-full lg:w-6/12 mx-auto">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              Transform your testing workflows with our comprehensive automation
              testing platform.
            </h4>
          </Reveal>
        </div>
        <div className="flex flex-col gap-y-10 w-full lg:w-10/12 mx-auto">
          <div className="flex flex-col items-center gap-y-5 lg:flex-row">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img src={StreamlineProcess} alt="streamline-process" />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">
                  Streamlined Process
                </h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Automate test case creation and execution
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Ensure consistent test coverage across all scenarios
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Integrate seamlessly with CI/CD pipelines for continuous
                      testing
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Generate comprehensive test reports for analysis
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-y-5 lg:flex-row-reverse">
            <div className="w-full lg:w-6/12">
              <Reveal>
                <img
                  src={PowerfulTools}
                  alt="powerful-tools"
                  className="mx-auto"
                />
              </Reveal>
            </div>
            <div className="w-full lg:w-6/12 text-left">
              <Reveal>
                <h5 className="text-white text-4xl font-bold">
                  Powerful Tools
                </h5>
              </Reveal>
              <div className="flex flex-col gap-y-3 mt-5">
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Utilize a wide range of automation testing frameworks
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Support for cross-browser and cross-platform testing
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Easily manage test scripts and configurations
                    </p>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="flex items-center gap-x-3">
                    <FaCircleCheck size={20} color="#0cf67f" />
                    <p className="text-greyTone">
                      Seamless integration with popular testing tools and IDEs
                    </p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 rounded-big py-5 px-2 md:py-14 md:px-10 bg-lightMainColor border-2 border-borderColor text-center flex flex-col gap-y-5 lg:flex-row items-center">
        <div className="w-full lg:w-6/12">
          <Reveal>
            <img src={RobustEco} alt="robust-eco" className="mx-auto" />
          </Reveal>
        </div>
        <div className="w-full lg:w-6/12 text-left">
          <Reveal>
            <h4 className="text-white text-xl lg:text-4xl">
              Robust Testing Ecosystem
            </h4>
          </Reveal>

          <Reveal>
            <p className="text-greyTone mt-4 mb-10">
              Our automation testing platform provides a comprehensive ecosystem
              for all your testing needs. From unit tests to end-to-end
              scenarios, we offer the tools and integrations necessary for
              successful test automation. Elevate your testing capabilities and
              deliver flawless software with confidence.
            </p>
          </Reveal>
          <Reveal>
            <CustomButton text="Explore Features" />
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default AutomationTesting;
