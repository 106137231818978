import React, { useEffect, useState } from "react";
import { RxCaretRight, RxCaretUp } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { LuArrowRight } from "react-icons/lu";
import { HiMenu } from "react-icons/hi";
import { HiX } from "react-icons/hi";
import logo from "./../../../media/autonomu_octopus.svg";
import { motion } from "framer-motion";

function Navbar() {
  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });
  const [mobileMenu, setMobileMenu] = useState(false);
  const [subMenuProduct, setSubMenuProduct] = useState(false);
  const [subMenuSolutions, setSubMenuSolutions] = useState(false);

  const showMobileMenu = () => {
    setMobileMenu(true);
  };
  const hideMobileMenu = () => {
    setMobileMenu(false);
    setSubMenuProduct(false);
    setSubMenuSolutions(false);
  };

  const showSubMenuProduct = () => {
    setSubMenuProduct(!subMenuProduct);
  };
  const showSubMenuSolutions = () => {
    setSubMenuSolutions(!subMenuSolutions);
  };

  useEffect(() => {
    hideMobileMenu(); //hide mobile menu when url changes
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const transition = { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.9] };

  const animEffectMain = {
    initial: {
      x: 0,
    },
    animate: {
      x: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
    exit: {
      x: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const animEffect = {
    initial: {
      x: 1000,
    },
    animate: {
      x: 0,
      transition: {
        duration: 1,
        ...transition,
      },
    },
    exit: {
      x: 1000,
      transition: {
        duration: 1,
        ...transition,
      },
    },
  };

  return (
    <nav>
      {windowSize.width < 800 ? (
        <div>
          <HiMenu color="white" size={30} onClick={showMobileMenu} />
          {mobileMenu && (
            <motion.div initial="initial" animate="animate" exit="exit">
              <motion.ul
                variants={animEffectMain}
                className="flex flex-col gap-y-10 py-8 px-5 w-full h-full my-0 mx-auto fixed overflow-y-scroll bg-[#252427] top-0 left-0 z-50"
              >
                <div className="flex items-center justify-between">
                  <Link id="RouterNavLink" to="/">
                    <img src={logo} alt="site-logo" className="w-3/12 " />
                  </Link>
                  <HiX color="white" size={30} onClick={hideMobileMenu} />
                </div>
                <motion.div variants={animEffect}>
                  <Link id="RouterNavLink" to="/about">
                    <li>
                      <p className="text-[#f1f1f1] text-4xl hover:text-white transition-all">
                        About
                      </p>
                    </li>
                  </Link>
                </motion.div>
                <motion.div variants={animEffect}>
                  <li>
                    <div className="flex justify-between">
                      <Link
                        id="RouterNavLink"
                        to="/product"
                        className="text-[#f1f1f1] text-4xl hover:text-white transition-all"
                      >
                        Product
                      </Link>
                      <RxCaretRight
                        size={40}
                        className={`text-[#f1f1f1] ${
                          subMenuProduct ? "rotate-90" : ""
                        } transition-all ease-in-out`}
                        onClick={showSubMenuProduct}
                      />
                    </div>
                    <div
                      className={`mt-5 ${subMenuProduct ? "block" : "hidden"}`}
                    >
                      <p className="uppercase font-bold text-lg text-white/60 tracking-widest">
                        Integration
                      </p>
                      <ul className="flex flex-col gap-y-3 mt-4 [&>*]:border-b [&>*]:border-[#171617] [&>*]:pb-4">
                        <Link to="/product/product-detail/power-automate">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Power Automate
                            </p>

                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                        <Link to="/product/product-detail/zapier">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">Zapier</p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>

                        <Link to="/product/product-detail/slack">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">Slack</p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </li>
                </motion.div>
                <motion.div variants={animEffect}>
                  <li>
                    <div className="flex justify-between">
                      <Link
                        id="RouterNavLink"
                        to="/solutions"
                        className="text-[#f1f1f1] text-4xl hover:text-white transition-all"
                      >
                        Solutions
                      </Link>
                      <RxCaretRight
                        size={40}
                        className={`text-[#f1f1f1] ${
                          subMenuSolutions ? "rotate-90" : ""
                        } transition-all ease-in-out`}
                        onClick={showSubMenuSolutions}
                      />
                    </div>
                    <div
                      className={`mt-5 ${
                        subMenuSolutions ? "block" : "hidden"
                      }`}
                    >
                      <ul className="flex flex-col gap-y-3 mt-4 [&>*]:border-b [&>*]:border-[#171617] [&>*]:pb-4">
                        <Link to="/solutions/web-automation">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Web Automation
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                        <Link to="/solutions/pipeline-testing">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Build Pipeline Testing
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                        <Link to="/solutions/data-integration">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Data Integration
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                        <Link to="/solutions/automated-testing">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Automated Testing
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>

                        <Link to="/solutions/workflow-orchestration">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Workflow Orchestration
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                        <Link to="/solutions/compliance-automation">
                          <li className="flex items-center justify-between">
                            <p className="text-[#f1f1f1] text-lg">
                              Compliance Automation
                            </p>
                            <LuArrowRight
                              size={20}
                              className="text-[#f1f1f1]"
                            />
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </li>
                </motion.div>
                <motion.div variants={animEffect}>
                  <Link id="RouterNavLink" to="/pricing">
                    <li>
                      <p className="text-[#f1f1f1] text-4xl hover:text-white transition-all">
                        Pricing
                      </p>
                    </li>
                  </Link>
                </motion.div>
                <motion.div variants={animEffect} className="mt-20">
                  <Link id="RouterNavLink" to="/contact">
                    <li>
                      <button className="bg-primaryColor hover:bg-accentColor rounded-big px-4 py-3 flex items-center justify-center gap-x-2 transition-all duration-500 ease-out w-full">
                        <p className="font-semibold text-white text-xl">
                          Try For Free
                        </p>
                        <LuArrowRight
                          size={25}
                          className="text-white group-hover:translate-x-2 transition-transform duration-300"
                        />
                      </button>
                    </li>
                  </Link>
                </motion.div>
              </motion.ul>
            </motion.div>
          )}
        </div>
      ) : (
        <ul className="flex items-center gap-x-10">
          <Link id="RouterNavLink" to="/about">
            <li>
              <p
                className={`${
                  location.pathname === "/about"
                    ? "text-white"
                    : "text-white/60"
                } hover:text-white transition-all">`}
              >
                About
              </p>
            </li>
          </Link>

          <li className="relative group">
            <Link id="RouterNavLink" to="/product">
              <div className="flex items-center gap-x-1">
                <p
                  className={`${
                    location.pathname === "/product"
                      ? "text-white"
                      : "text-white/60"
                  } hover:text-white transition-all">`}
                >
                  Product
                </p>
                <RxCaretUp
                  size={20}
                  className="text-white/60 group-hover:text-white font-bold transform group-hover:rotate-180 transition-all duration-300 ease-in-out"
                />
              </div>
            </Link>
            <div className="absolute top-0 -left-48 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-600 ease-in-out group-hover:transform z-50 min-w-[550px] transform">
              <div className="relative top-4 p-6 bg-[#1a253d] border border-borderColor rounded-lg shadow-xl w-full ">
                <p className="uppercase tracking-widest text-[12px] p-3 text-white/60">
                  Integration
                </p>

                <div className="grid grid-cols-2">
                  <Link
                    id="RouterNavLink"
                    to="/product/product-detail/power-automate"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Power Automate
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                      nmquam ducimus.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/product/product-detail/zapier"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">Zapier</p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                      nmquam ducimus.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/product/product-detail/slack"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">Slack</p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                      nmquam ducimus.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <li className="relative group">
            <Link id="RouterNavLink" to="/solutions">
              <div className="flex items-center gap-x-1">
                <p
                  className={`${
                    location.pathname === "/solutions"
                      ? "text-white"
                      : "text-white/60"
                  } hover:text-white transition-all">`}
                >
                  Solutions
                </p>
                <RxCaretUp
                  size={20}
                  className="text-white/60 group-hover:text-white font-bold transform group-hover:rotate-180 transition-all duration-300 ease-in-out"
                />
              </div>
            </Link>
            <div className="absolute top-0 -left-48 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-600 ease-in-out group-hover:transform z-50 min-w-[550px] transform">
              <div className="relative top-4 p-6 bg-[#1a253d] border border-borderColor rounded-lg shadow-xl w-full ">
                <div className="grid grid-cols-2">
                  <Link
                    id="RouterNavLink"
                    to="/solutions/web-automation"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Web Automation
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Effortlessly automate web processes for enhanced
                      productivity.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/solutions/pipeline-testing"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Build Pipeline Testing
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Streamline pipeline testing to ensure seamless development
                      workflows.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/solutions/automated-testing"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Automated Testing
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Automate testing processes to accelerate software
                      deployment and ensure reliability..
                    </p>
                  </Link>

                  <Link
                    id="RouterNavLink"
                    to="/solutions/data-integration"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Data Integration
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Seamlessly integrate data across platforms for enhanced
                      decision-making.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/solutions/workflow-orchestration"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Workflow Orchestration
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Optimize workflow orchestration for streamlined operations
                      and improved efficiency.
                    </p>
                  </Link>
                  <Link
                    id="RouterNavLink"
                    to="/solutions/compliance-automation"
                    className="rounded-lg p-3 transition-all hover:bg-[#172035]"
                  >
                    <p className="text-white/90 m-0 font-semibold">
                      Compliance Automation
                    </p>
                    <p className="text-white/40 text-[12px] mt-1">
                      Automate compliance processes to ensure adherence to
                      regulations and standards.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </li>

          <Link id="RouterNavLink" to="/pricing">
            <li>
              <p
                className={`${
                  location.pathname === "/pricing"
                    ? "text-white"
                    : "text-white/60"
                } hover:text-white transition-all">`}
              >
                Pricing
              </p>
            </li>
          </Link>
          <Link id="RouterNavLink" to="/contact">
            <li className="relative group">
              <button className="bg-primaryColor hover:bg-accentColor rounded-big px-4 py-3 flex items-center gap-x-2 transition-all duration-500 ease-out">
                <p className="font-semibold text-white text-nowrap">
                  Try For Free
                </p>
                <LuArrowRight
                  size={20}
                  className="text-white group-hover:translate-x-2 transition-transform duration-300"
                />
              </button>
            </li>
          </Link>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
