import { GrSystem } from "react-icons/gr";
import { MdWeb } from "react-icons/md";
import { PiChatsCircleBold } from "react-icons/pi";

const AddOnData = [
  {
    icon: <GrSystem size={22} />,
    title: "Process Schemes",
    subTitle:
      "Construct automated Workflow solutions to fuel your essential business functions. Test it out at no cost.",
    price: "20",
  },
  {
    icon: <MdWeb size={22} />,
    title: "Enhanced Interfaces Add-on",
    subTitle:
      "Develop tailored forms, webpages, and applications to empower your workflows—no coding needed. Experience it for free.",
    price: "49",
  },
  {
    icon: <PiChatsCircleBold size={22} />,
    title: "Advanced Chatbots Add-on (beta)",
    subTitle:
      "Craft advanced, AI-driven chatbots and execute actions with integrated automation capabilities. Explore it for free.",
    price: "99",
  },
];

export default AddOnData;
